/**
 * Temp fix - should be replaced as part of Fender #7909
 */
export default class Sanitiser {
  // https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html
  public static sanitise(content: string | undefined): string {
    if (!content) {
      return '';
    }

    let ret = content;
    ret = ret.replaceAll('<', '&lt;');
    ret = ret.replaceAll('>', '&gt;');
    ret = ret.replaceAll('"', '&quot;');
    ret = ret.replaceAll('\'', '&#x27;');
    return ret;
  }

  public static safeStringify(obj: unknown): string {
    try {
      return JSON.stringify(obj);
    } catch (Error) {
      return 'Can\'t JSON.stringify (Circular ref?)';
    }
  }
}
