import { Logger } from 'loglevel';

import { getPrefixedLogger, onLogInit } from './LogHelper';

export let perfLog: Logger;
onLogInit(() => { perfLog = getPrefixedLogger('perf', 'green'); });

export function perfMsg(msg: string, includePerfNow?: boolean, includeDurationFrom?: number | null): string {
  let now: number = 0;
  if (includeDurationFrom || includePerfNow) {
    now = performance.now();
  }
  let logMsg = `${msg}`;
  if (includeDurationFrom) {
    logMsg += ` in ${now - includeDurationFrom}ms`;
  }
  if (includePerfNow) {
    logMsg += ` [${now}]`;
  }
  return logMsg;
}
