import log from 'loglevel';

import { perfLog, perfMsg } from '../log/PerformanceLog';

export interface PagedResult {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  objects: any[];
  subsequentPageExists: boolean;
}

export default abstract class AbstractDb {
  protected dbFactory: IDBFactory;
  protected dbName: string;
  protected version: number | undefined;
  protected db!: IDBDatabase;

  constructor(dbName: string, version: number) {
    if (typeof window !== 'undefined') {
      // the database is being opened by the main thread
      this.dbFactory = window.indexedDB;
    } else {
      // the database is being opened by a worker thread
      this.dbFactory = self.indexedDB;
    }
    this.dbName = dbName;
    this.version = version;
  }

  public get databaseName(): string {
    return this.dbName;
  }

  public get databaseVersion(): number | undefined {
    return this.version;
  }

  public async open(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const openStart: number = performance.now();
      perfLog.debug(perfMsg('Opening DB'));
      const request: IDBOpenDBRequest = this.dbFactory.open(this.dbName, this.version);
      request.onsuccess = () => {
        log.debug('open.onsuccess');
        perfLog.debug(perfMsg('DB opened', false, openStart));
        // event.target is the IDBOpenDBRequest, so use that to avoid having to cast
        this.db = request.result;
        this.db.onversionchange = () => {
          this.handleVersionChangeRequest();
        };
        resolve(request.result);
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      request.onerror = (event: any) => {
        log.debug('open.onerror');
        log.error(`Database error: ${event && event.target ? event.target.errorCode : null}`);
        reject(new Error(request.error?.message));
      };

      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        log.info(`Upgrading DB from ${event.oldVersion} to ${event.newVersion}`);
        this.db = request.result;

        this.updateDbVersion(request, event.oldVersion, event.newVersion).then(() => resolve(request.result));
      };
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getAll(store: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const objectStore: IDBObjectStore = this.getReadOnlyTransaction([store]).objectStore(store);
      if (objectStore.getAll) {
        const req: IDBRequest = objectStore.getAll();
        req.onsuccess = () => {
          resolve(req.result);
        };
        req.onerror = () => {
          log.error(`${store} req.onerror: ${req.error?.message}`);
          reject(new Error(`nope :(: ${req.error?.message}`));
        };
      } else {
        log.debug('Edge workaround for getAll');
        // Workaround for Edge not having getAll
        const req: IDBRequest = objectStore.openCursor();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any[] = [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        req.onsuccess = (event: any) => {
          const cursor: IDBCursorWithValue = event.target.result;
          if (cursor) {
            result.push(cursor.value);
            cursor.continue();
          } else {
            resolve(result);
          }
        };
        req.onerror = () => {
          reject(new Error(`nope :(: ${req.error?.message}`));
        };
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async get(store: string, query: IDBValidKey | IDBKeyRange): Promise<any> {
    return new Promise((resolve, reject) => {
      const objectStore: IDBObjectStore = this.getReadOnlyTransaction([store]).objectStore(store);
      const req: IDBRequest = objectStore.get(query);
      req.onsuccess = () => {
        resolve(req.result);
      };
      req.onerror = () => {
        reject(new Error(`get failed: ${req.error?.message}`));
      };
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getByIndex(store: string, indexName: string, query?: IDBValidKey | IDBKeyRange): Promise<any> {
    return new Promise((resolve, reject) => {
      const objectStore: IDBObjectStore = this.getReadOnlyTransaction([store]).objectStore(store);
      const index: IDBIndex = objectStore.index(indexName);
      const req: IDBRequest = index.openCursor(query);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result: any[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      req.onsuccess = (event: any) => {
        const cursor: IDBCursorWithValue = event.target.result;
        if (cursor) {
          result.push(cursor.value);
          cursor.continue();
        } else {
          resolve(result);
        }
      };
      req.onerror = () => {
        reject(new Error(`getByIndex failed: ${req.error?.message}`));
      };
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getMaxValueByIndex(store: string, indexName: string, query?: IDBValidKey | IDBKeyRange): Promise<any> {
    return this.getSingleValueByIndex(store, indexName, 'prev', query);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getMinValueByIndex(store: string, indexName: string, query?: IDBValidKey | IDBKeyRange): Promise<any> {
    return this.getSingleValueByIndex(store, indexName, 'next', query);
  }

  private async getSingleValueByIndex(store: string, indexName: string, direction: IDBCursorDirection,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query?: IDBValidKey | IDBKeyRange): Promise<any> {
    return new Promise((resolve, reject) => {
      const objectStore: IDBObjectStore = this.getReadOnlyTransaction([store]).objectStore(store);
      const index: IDBIndex = objectStore.index(indexName);
      const req: IDBRequest = index.openCursor(query, direction);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      req.onsuccess = (event: any) => {
        const cursor: IDBCursorWithValue = event.target.result;
        if (cursor) {
          resolve(cursor.value);
        } else {
          resolve(undefined);
        }
      };
      req.onerror = () => {
        reject(new Error(`getSingleValueByIndex failed: ${req.error?.message}`));
      };
    });
  }

  /*
   * Get the most recent X objects, ordered in descending order.
   * Also check whether at least one more page size worth of objects exists in the store if required.
   */
  async getPageByIndex(store: string, indexName: string, pageSize: number, lookAhead: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query?: IDBValidKey | IDBKeyRange): Promise<any> {
    return new Promise((resolve, reject) => {
      const objectStore: IDBObjectStore = this.getReadOnlyTransaction([store]).objectStore(store);
      const index: IDBIndex = objectStore.index(indexName);
      const req: IDBRequest = index.openCursor(query, 'prev');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result: PagedResult = {
        objects: [],
        subsequentPageExists: false,
      };
      let subsequentObjectCount: number = 0;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      req.onsuccess = (event: any) => {
        const cursor: IDBCursorWithValue = event.target.result;
        if (cursor) {
          if (result.objects.length < pageSize) {
            result.objects.push(cursor.value);
            cursor.continue();
          } else if (lookAhead) {
            if (subsequentObjectCount < pageSize) {
              if (++subsequentObjectCount >= pageSize) {
                result.subsequentPageExists = true;
                resolve(result);
              } else {
                cursor.continue();
              }
            }
          } else {
            resolve(result);
          }
        } else {
          resolve(result);
        }
      };
      req.onerror = () => {
        reject(new Error(`getPageByIndex failed: ${req.error?.message}`));
      };
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  async set(store: string, value: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const req: IDBRequest = this.getReadWriteTransaction([store]).objectStore(store).put(value);
      req.onsuccess = () => {
        resolve(req.result);
      };
      req.onerror = () => {
        reject(new Error(`nope :(: ${req.error?.message}`));
      };
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  async setValues(store: string, values: any[]): Promise<number> {
    return new Promise((resolve, reject) => {
      const transaction: IDBTransaction = this.getReadWriteTransaction([store]);
      for (const value of values) {
        transaction.objectStore(store).put(value);
      }
      transaction.oncomplete = () => {
        resolve(values.length);
      };
      transaction.onerror = () => {
        reject(new Error(`transaction on ${store} failed: ${transaction.error?.message}`));
      };
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  async setKVP(store: string, key: string, value: any): Promise<any> {
    log.debug(`set ${store} - ${key} - ${value}`);
    return new Promise((resolve, reject) => {
      const req: IDBRequest = this.getReadWriteTransaction([store]).objectStore(store).put({ id: key, value: value });
      req.onsuccess = () => {
        resolve(req.result);
      };
      req.onerror = () => {
        reject(new Error(`nope :(: ${req.error?.message}`));
      };
    });
  }

  async delete(store: string, key: string): Promise<void> {
    log.debug(`delete ${store} - ${key}`);
    return new Promise((resolve, reject) => {
      const req: IDBRequest = this.getReadWriteTransaction([store]).objectStore(store).delete(key);
      req.onsuccess = () => {
        resolve();
      };
      req.onerror = () => {
        log.error('delete req.onerror');
        reject(new Error(`delete failed: ${req.error?.message}`));
      };
    });
  }

  async deleteItems(store: string, keys: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      const transaction: IDBTransaction = this.getReadWriteTransaction([store]);
      for (const key of keys) {
        transaction.objectStore(store).delete(key);
      }
      transaction.oncomplete = () => {
        resolve();
      };
      transaction.onerror = () => {
        reject(new Error(`delete transaction on ${store} failed: ${transaction.error?.message}`));
      };
    });
  }

  async clearObjectStore(store: string): Promise<void> {
    log.debug(`clear ${store}`);
    return new Promise((resolve, reject) => {
      const req: IDBRequest = this.getReadWriteTransaction([store]).objectStore(store).clear();
      req.onsuccess = () => {
        log.debug(`clear ${store} succeeded`);
        resolve();
      };
      req.onerror = () => {
        reject(new Error(`clear ${store} failed: ${req.error?.message}`));
      };
    });
  }

  private getReadOnlyTransaction(stores: string[]): IDBTransaction {
    return this.db.transaction(stores, 'readonly');
  }

  private getReadWriteTransaction(stores: string[]): IDBTransaction {
    const transaction: IDBTransaction = this.db.transaction(stores, 'readwrite');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transaction.onabort = (event: any) => {
      // Apparently this is how to detect an exceeded quota, but it doesn't seem to be the case in Chrome:
      let quotaExceeded: boolean = event.name === 'QuotaExceededError';
      if (!quotaExceeded) {
        // This seems to be what Chrome does:
        if (event.target && event.target.error && event.target.error.name) {
          quotaExceeded = event.target.error.name === 'QuotaExceededError';
        }
      }
      if (quotaExceeded) {
        // The request onsuccess callback has already been called even though the insert failed, so
        // there's not a lot we can do about that
        this.handleExceededQuota();
      }
    };
    return transaction;
  }

  abstract updateDbVersion(request: IDBOpenDBRequest, oldVersion: number, newVersion: number | null):
      Promise<void>;

  protected abstract handleExceededQuota(): Promise<void>;

  protected abstract handleVersionChangeRequest(): void;
}
